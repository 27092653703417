import React, { useState, useEffect, useCallback, useRef } from "react";
import { useHistory } from "react-router-dom";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Geosuggest from "react-geosuggest";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import ListSubheader from "@material-ui/core/ListSubheader";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputMask from "react-input-mask";
import { toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import Button from "../../../components/button/button";
import StoreSeviceTypeList from "../../../components/store/store";
import Modal from "react-modal";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from '@material-ui/icons/Close';
// import Checkbox from "@material-ui/core/Checkbox";
import { getMethod, postMethodWithTokenProgress, postMethodWithToken, putMethodWithToken } from "../../../utils/services/api";
import urls from "../../../utils/services/apiUrl.json";
import { getStoreId } from "../../../utils/common";
import "./aboutStore.scss";
import UploadPhotoIcon from "../../../assets/images/add_a_photo.png";
import PDF from "../../../assets/images/pdfHolder.svg";
import DOC from "../../../assets/images/doc.png"
import InfoIcon from '@material-ui/icons/Info';
import { Tooltip } from 'antd';

let latitude = "";
let longitude = "";

const checkAboutInfo = aboutData => {
    const emptyKeys = [
        'name',
        'first_name',
        'last_name',
        'address',
        'country',
        'state',
        'city',
        'postal_code',
        'contact_number',
        'type_of_store',
        'category',
        // 'store_icon',
        'doc_ids'
    ];

    const emptyKeysErrors = [
        'Store Name',
        'First Name',
        'Last Name',
        'Address',
        'Country',
        'State',
        'City',
        'ZIP/Postal Code',
        'Phone Number',
        'Type of Store',
        'Category',
        // 'Store Icon',
        // 'Upload Document',
        'Business Document'
    ];

    for (let i = 0; i < emptyKeys.length; i++) {
        if (!aboutData[emptyKeys[i]]) {
            return `Please Fill in ${emptyKeysErrors[i]}`;
        }

        if (emptyKeys[i] === 'type_of_store' && aboutData[emptyKeys[i]].length === 0) {
            return `Please Fill in ${emptyKeysErrors[i]}`;
        }

        if (emptyKeys[i] === 'category' && aboutData[emptyKeys[i]].length === 0) {
            return `Please Fill in ${emptyKeysErrors[i]}`;
        }

        // if (emptyKeys[i] === 'store_icon' && aboutData[emptyKeys[i]].length === 0) {
        //     return `Please fill in ${emptyKeysErrors[i]}`;
        // }

        if (emptyKeys[i] === 'doc_ids' && aboutData[emptyKeys[i]].length === 0) {
            return `Please Upload ${emptyKeysErrors[i]}`;
        }
    }
}

function AboutStore(props) {
    const geosuggestEl = useRef("");
    const google = window.google;
    const history = useHistory();

    const allStyles = {
        content: {
            top: "15vw",
            left: "50vw",
            right: "auto",
            transform: "translate(-50%, -5%)",
            backgroundColor: "#fff",
            border: "1px #ccc",
            borderRadius: "4px",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            width: "40vw",
            height: "60vh",
            outline: "none",
            padding: "20px",
        },
    };

    const [loader, setLoader] = useState(false);
    const [lat, setLat] = useState(0);
    const [lng, setLng] = useState(0);
    const [countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [stateDataList, setStateDataList] = useState([]);
    const [cityDataList, setCityDataList] = useState([]);
    const [countrySelected, setCountrySelected] = useState("");
    const [stateSelected, setStateSelected] = useState("");
    const [citySelected, setCitySelected] = useState("");
    const [stateName, setStateName] = useState("");
    const [cityName, setCityName] = useState("");
    const [storeIsOpen, setStoreIsOpen] = useState(false);
    const [categoryIsOpen, setCategoryIsOpen] = useState(false);
    const [typeOfStore, setTypeOfStore] = useState([]);
    const [storeCategory, setStoreCategory] = useState([]);
    const [openStoreCategories, setOpenStoreCategories] = useState([]);
    const [openCategory, setOpenCategory] = useState([]);
    const [revenueOptions, setRevenueOptions] = useState([]);
    //const [revenueChecked, setRevenueChecked] = useState(false);
    const [uploadPercentage, setStateUploadPercentage] = useState(0);
    const [docsImagesList, setDocsImagesList] = useState([]);
    const [aboutInfoUpdate, setAboutInfoUpdate] = useState(false);
    const dragItem = useRef();
    const dragOverItem = useRef();

    const [state, setState] = useState({
        store_info: getStoreId(),
        name: "",
        first_name: "",
        last_name: "",
        address: "",
        unit: "",
        country: "",
        state: "",
        city: "",
        postal_code: "",
        contact_number: "",
        website: "",
        location: "",
        current_annual_revenue: "",
        //revenue_disclose: false,
        type_of_store: [],
        category: [],
        store_icon: [],
        store_logo: [],
        doc_ids: [],
        latitude: "",
        longitude: "",
    });

    useEffect(() => {
        getCurrentStatus();
        getCountryList();
        getAnualRevenueList();
        getStoreData();
    }, []);

    window.$progressStatus = "20%";

    const getCurrentStatus = async () => {
        await getMethod(urls.getUserDetailsMe).then((response) => {
            if (response.status === 200) {
                window.$step = response.data.signup_step;
                if (response.data.signup_step <= 0) {
                    localStorage.setItem("progressStatus", "0");
                }
            }
        }).catch((error) => {
            console.log("error", error);
        });
    };

    const getStoreData = async () => {
        let storeId = getStoreId();
        await getMethod(urls.aboutStore + storeId).then((response) => {
            if (response.status === 200) {
                if (response.data.business_address.id) {
                    setAboutInfoUpdate(true)
                }

                let countryValue = response.data.business_address.country;
                let stateValue = response.data.business_address.state;
                let cityValue = response.data.business_address.city;
                let state_id = '';
                setCountrySelected(countryValue);
                if (countryValue) {
                    getMethod(urls.getStates + '?country_name=' + countryValue).then((response) => {
                        if (response.status === 200) {
                            let results = response.data.response[0].data.states

                            setStateList(results);
                            setStateDataList(results);

                            if (results.length > 0 && stateValue) {
                                let indexStateID = '';

                                results.map((item) => {
                                    if (item.stateName === stateValue) {
                                        indexStateID = item.id;
                                    }
                                });

                                if (indexStateID) {
                                    state_id = indexStateID;
                                    setStateSelected(indexStateID);

                                    setState((prevState) => ({
                                        ...prevState,
                                        state: indexStateID,
                                    }));

                                    if (countryValue && state_id) {
                                        getCityListWithName(countryValue, state_id, cityValue)
                                    }
                                }
                            }
                        }
                    });
                }

                if (response.data.business_address.store_icon && response.data.business_address.store_icon.length > 0) {
                    let idObject = {
                        image_id: response.data.business_address.store_icon[0].id,
                        order_image: response.data.business_address.store_icon[0].order_image,
                        file: response.data.business_address.store_icon[0].file
                    };

                    setState((prevState) => ({
                        ...prevState,
                        ['store_icon']: [idObject],
                    }));
                }

                if (response.data.business_address.store_logo && response.data.business_address.store_logo.length > 0) {
                    let idObject = {
                        image_id: response.data.business_address.store_logo[0].id,
                        order_image: response.data.business_address.store_logo[0].order_image,
                        file: response.data.business_address.store_logo[0].file
                    };

                    setState((prevState) => ({
                        ...prevState,
                        ['store_logo']: [idObject],
                    }));
                }

                if (response.data.business_address.business_doc && response.data.business_address.business_doc.length > 0) {
                    let storeDocsList = [];

                    response.data.business_address.business_doc.forEach((x, i) => {
                        let idObject = {
                            image_id: x.id,
                            order_image: x.order_image,
                            file: x.file
                        };

                        storeDocsList.push(idObject);
                    });
                    setDocsImagesList(storeDocsList);
                }

                setOpenStoreCategories(response.data.type_of_store);
                for (let j = 0; j < response.data.type_of_store.length; j++) {
                    typeOfStore.push(response.data.type_of_store[j].slug);
                }
                for (let k = 0; k < response.data.category.length; k++) {
                    storeCategory.push(response.data.category[k].slug);
                }
                setOpenCategory(response.data.category);

                setState((prevState) => ({
                    ...prevState,
                    ["name"]: response.data.name,
                    ["first_name"]: response.data.business_address.first_name,
                    ["last_name"]: response.data.business_address.last_name,
                    ["address"]: response.data.business_address.address,
                    ["unit"]: response.data.business_address.unit,
                    ["country"]: countryValue,
                    ["postal_code"]: response.data.business_address.postal_code,
                    ["contact_number"]: response.data.business_address.contact_number,
                    ["website"]: response.data.business_address.website,
                    ["location"]: response.data.business_address.location,
                    ["latitude"]: response.data.business_address.latitude,
                    ["longitude"]: response.data.business_address.longitude,
                    ["current_annual_revenue"]: response.data.current_annual_revenue.slug,
                    //["revenue_disclose"]: response.data.revenue_disclose
                }));
            } else {
                toast.error(response.data.error)
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    const getAnualRevenueList = async () => {
        await getMethod(urls.storeAnnualRevenue).then((response) => {
            if (response.status === 200) {
                setRevenueOptions(response.data);
            }
        });
    };

    const getCountryList = async () => {
        await getMethod(urls.getCountry).then((response) => {
            if (response.status === 200) {
                setCountryList(response.data);
            }
        });
    };

    const getStateList = async (value) => {
        await getMethod(urls.getStates + '?country_name=' + value).then((response) => {
            if (response.status === 200) {
                setStateList(response.data.response[0].data.states);
                setStateDataList(response.data.response[0].data.states);
            }
        });
    };

    const getCityList = async (value) => {
        await getMethod(urls.getStates + '?country_name=' + state.country + '&state_id=' + value).then((response) => {
            if (response.status === 200) {
                setCityList(response.data.response[0].data.cities);
                setCityDataList(response.data.response[0].data.cities);
            }
        });
    };

    const getCityListWithName = async (countryId, stateId, name) => {
        await getMethod(urls.getStates + '?country_name=' + countryId + '&state_id=' + stateId).then((response) => {
            if (response.status === 200) {
                let results = response.data.response[0].data.cities;
                setCityList(results);
                setCityDataList(results);

                if (name && results.length > 0) {
                    let indexCityID = '';

                    results.map((item) => {
                        if (item.cityName === name) {
                            indexCityID = item.id;
                        }
                    });

                    if (indexCityID) {
                        setCitySelected(indexCityID)

                        setState((prevState) => ({
                            ...prevState,
                            city: indexCityID,
                        }));
                    }
                }

            }
        });
    }

    const handleCountryChange = (event) => {
        setCountrySelected(event.target.value);
        setState({ ...state, [event.target.name]: event.target.value });
        setState((prevState) => ({
            ...prevState,
            state: '',
            city: '',
            postal_code: '',
        }));
        setStateSelected('')
        setCitySelected('');
        getStateList(event.target.value);
    };

    const handleStateChange = (event) => {
        if (event.target.value !== undefined) {
            setStateSelected(event.target.value);
            setState({ ...state, [event.target.name]: event.target.value });
            setState((prevState) => ({
                ...prevState,
                city: '',
            }));
            setCitySelected('');
            getCityList(event.target.value);
        }
    };

    const handleCityChange = (event) => {
        if (event.target.value !== undefined) {
            setCitySelected(event.target.value);
            setState({ ...state, [event.target.name]: event.target.value });
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const sendDataToParent = (item) => {
        const list = [...typeOfStore];
        const openList = [...openStoreCategories];
        if (list.includes(item.slug) === false) {
            list.push(item.slug);
            openList.push(item);
        } else {
            const index = typeOfStore.indexOf(item.slug);

            if (index > -1) {
                list.splice(index, 1);
                openList.splice(index, 1);
            }
        }
        setTypeOfStore(list);
        setOpenStoreCategories(openList);
    };

    const sendDataToCategory = (item) => {
        const list = [...storeCategory];
        const openList = [...openCategory];
        if (list.includes(item.slug)) {
            const index = storeCategory.indexOf(item.slug);
            if (index > -1) {
                list.splice(index, 1);
                openList.splice(index, 1);
            }
        } else {
            list.push(item.slug);
            openList.push(item);
        }
        setStoreCategory(list);
        setOpenCategory(openList);
    };
    /*const handleCheckbox = () => {
        setState((prevState) => ({
            ...prevState,
            ['revenue_disclose']: !revenueChecked,
        }));

        setRevenueChecked(!revenueChecked)

        if (!revenueChecked) {
            setState((prevState) => ({
                ...prevState,
                ['current_annual_revenue']: '',
            }));
        }
    };*/
    const onUploadProgress = (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        setStateUploadPercentage(percent);
    };


    const { getRootProps: getStoreLogo, getInputProps: getStoreInputLogo, isDragActive: getStoreLogoActive } = useDropzone({
        accept: '.jpg, .png',
        onDrop: (acceptedFile) => {
            let formData = new FormData();
            acceptedFile.map((file, index) => {
                formData.append("file", file);
            });

            postMethodWithTokenProgress(urls.fileUpload, formData, onUploadProgress).then((response) => {
                if (response.status === 200) {
                    let idObject = {
                        image_id: response.data[0].id,
                        order_image: 1,
                        file: response.data[0].file
                    };

                    setState((prevState) => ({
                        ...prevState,
                        ['store_logo']: [idObject],
                    }));
                } else {
                    toast.error("Please Upload Image In  The Following Types: JPG And PNG")
                }
            }).catch((error) => {
                console.log("error", error);
            });

        }
    })

    const { getRootProps: getStoreIcon, getInputProps: getStoreIconInput, isDragActive: getStoreIconActive } = useDropzone({
        accept: '.jpg, .png',
        onDrop: (acceptedFile) => {
            let formData = new FormData();
            acceptedFile.map((file, index) => {
                formData.append("file", file);
            });

            postMethodWithTokenProgress(urls.fileUpload, formData, onUploadProgress).then((response) => {
                if (response.status === 200) {
                    let idObject = {
                        image_id: response.data[0].id,
                        order_image: 1,
                        file: response.data[0].file
                    };

                    setState((prevState) => ({
                        ...prevState,
                        ['store_icon']: [idObject],
                    }));
                } else {
                    toast.error("Please Upload The Following Types:JPG And PNG")
                }
            }).catch((error) => {
                console.log("error", error);
            });

        }
    })

    const { getRootProps: getStoreDocument, getInputProps: getStoreInputDocument, isDragActive: getStoreDocumentActive } = useDropzone({
        accept: ".pdf,.doc, .jpg, .png",
        onDrop: (acceptedFile) => {
            let formData = new FormData();
            acceptedFile.map((file, index) => {
                formData.append("file", file);
            });

            postMethodWithTokenProgress(urls.fileUpload, formData, onUploadProgress).then((response) => {
                if (response.status === 200) {
                    let storeDocsList = [...docsImagesList];
                    response.data.map((item, index) => {
                        let idObject = {
                            image_id: item.id,
                            order_image: storeDocsList.length > 0 ? storeDocsList.length + 1 : index + 1,
                            file: item.file
                        };

                        storeDocsList.push(idObject);
                    });
                    setDocsImagesList(storeDocsList);
                } else {
                    toast.error("Please Upload The Following Types:JPG,PNG,PDF And DOC")
                }
            }).catch((error) => {
                console.log("error", error);
            });
        }
    })

    const dragUploadStart = (e, position) => {
        dragItem.current = position;
    }

    const dragUploadEnter = (e, position) => {
        dragOverItem.current = position;
    }

    const dropUploadEnd = (e) => {
        const list = [...docsImagesList];

        const dragItemWithFile = list[dragItem.current];
        const dragOverItemWithFile = list[dragOverItem.current];

        list.splice(dragItem.current, 1);
        list.splice(dragOverItem.current, 0, dragItemWithFile);

        dragItem.current = null;
        dragOverItem.current = null;

        list.forEach((x, i) => {
            list[i]['order_image'] = i + 1;
        })

        setDocsImagesList(list)
    }

    const handleDeleteImage = (index) => {
        const list = [...docsImagesList];
        list.splice(index, 1);

        if (list.length > 0) {
            list.forEach((x, i) => {
                if (list.order_image !== 1) {
                    list.order_image = list.order_image - 1;
                }
            });
        }
        setDocsImagesList(list)
    };

    const onSuggestSelect = (suggest) => {

        if (suggest) {
            setCountrySelected('');
            setStateSelected('');
            setCitySelected('');

            setState((prevState) => ({
                ...prevState,
                postal_code: "",
                country: "",
                state: "",
                city: ""
            }));

            const components = suggest.gmaps.address_components;

            let state_id = '';
            let streetNumberValue = '';
            let routeValue = '';
            let cityValue = '';
            let postCodeValue = '';
            let stateValue = '';
            let stateAddressValue = '';
            let countryValue = '';
            let addressValue = '';

            for (let key of components) {
                if (key.types[0] === "locality" || key.types[1] === "sublocality") {
                    setCityName(key.long_name)
                    cityValue = key.long_name;
                }

                if (key.types[0] === "administrative_area_level_1") {
                    stateValue = key.long_name;
                    stateAddressValue = key.short_name;
                    setStateName(key.long_name)
                }

                if (key.types[0] === "country") {
                    if (key.short_name === 'US') {
                        setCountrySelected('USA')
                        countryValue = 'USA';

                        setState((prevState) => ({
                            ...prevState,
                            country: 'USA',
                        }));

                    } else if (key.short_name === 'CA') {
                        setCountrySelected('CAN')
                        countryValue = 'CAN';

                        setState((prevState) => ({
                            ...prevState,
                            country: 'CAN',
                        }));
                    } else {
                        setCountrySelected(key.short_name)
                        countryValue = key.short_name;

                        setState((prevState) => ({
                            ...prevState,
                            country: key.short_name,
                        }));
                    }
                }

                if (key.types[0] === "postal_code") {
                    postCodeValue = key.long_name;
                    setState((prevState) => ({
                        ...prevState,
                        postal_code: key.long_name,
                    }));
                }
                if (key.types[0] === "route") {
                    routeValue = key.short_name;
                }
                if (key.types[0] === "street_number") {
                    streetNumberValue = key.long_name;
                }
            }

            if (stateValue && countryValue) {
                getMethod(urls.getStates + '?country_name=' + countryValue).then((response) => {
                    if (response.status === 200) {
                        let results = response.data.response[0].data.states

                        setStateList(results);
                        setStateDataList(results);

                        if (results.length > 0) {
                            let indexStateID = '';

                            results.map((item) => {
                                if (item.stateName === stateValue) {
                                    indexStateID = item.id;
                                }
                            });

                            if (indexStateID) {
                                state_id = indexStateID;
                                setStateSelected(indexStateID);

                                setState((prevState) => ({
                                    ...prevState,
                                    state: indexStateID,
                                }));

                                if (countryValue && state_id) {
                                    getCityListWithName(countryValue, state_id, cityValue)
                                }
                            }
                        }
                    }
                });
            }

            if (streetNumberValue !== "") {
                addressValue += streetNumberValue;
            }

            if (routeValue !== "" && streetNumberValue !== "") {
                addressValue += ", " + routeValue;
            } else if (routeValue !== "") {
                addressValue += routeValue;
            }

            /*if(cityValue !== "") {
                addressValue += ", " + cityValue;
            }

            if(stateValue !== "") {
                addressValue += ", " + stateAddressValue;
            }

            if(countryValue !== "") {
                addressValue += ", " + countryValue;
            }

            if(postCodeValue !== "") {
                addressValue += ", " + postCodeValue;
            }*/

            latitude = suggest.location.lat;
            longitude = suggest.location.lng;

            setState((prevState) => ({
                ...prevState,
                ["location"]: addressValue,
                ["address"]: addressValue,
                ["latitude"]: latitude,
                ["longitude"]: longitude,
            }));

            geosuggestEl.current.update(addressValue);
        }
    };

    const stateFilter = (e) => {
        const keyword = e.target.value;
        if (keyword !== '') {
            const results = stateDataList.filter((item) => {
                return item.stateName.toLowerCase().startsWith(keyword.toLowerCase());
            });
            setStateList(results);
        } else {
            setStateList(stateDataList);
        }
    };

    const cityFilter = (e) => {
        const keyword = e.target.value;
        if (keyword !== '') {
            const results = cityDataList.filter((item) => {
                return item.cityName.toLowerCase().startsWith(keyword.toLowerCase());
            });
            setCityList(results);
        } else {
            setCityList(cityDataList);
        }
    };

    const onSubmitHandle = async () => {

        state['type_of_store'] = typeOfStore;
        state['category'] = storeCategory;
        state['doc_ids'] = docsImagesList;

        const error = checkAboutInfo(state);

        if (error) {
            toast.error(error)
        } else {
            if (aboutInfoUpdate === true) {
                await putMethodWithToken(urls.aboutStore + getStoreId() + "/", state).then((response) => {
                    if (response.status === 200) {
                        toast.success(response.data.success);
                        history.push("/brands");
                    } else {
                        toast.error(response.data.error);
                    }
                }).catch((error) => {
                    setLoader(false);
                });
            } else {
                await postMethodWithToken(urls.aboutStore, state).then((response) => {
                    if (response.status === 200) {
                        toast.success(response.data.success);
                        history.push("/brands");
                        setAboutInfoUpdate(true)
                    } else {
                        toast.success(response.data.error);
                    }
                }).catch((error) => {
                    setLoader(false);
                });
            }
        }
    };

    const viewAllStoreType = () => {
        setStoreIsOpen(true);
    };

    const closeAllStoreType = () => {
        setStoreIsOpen(false);
    };

    const viewAllCategoryType = () => {
        setCategoryIsOpen(true);
    };

    const closeAllCategoryType = () => {
        setCategoryIsOpen(false);
    };

    const checkFileExtension = (item) => {
        var fileExtension = item.file.split(".").pop();
        return fileExtension === "pdf";
    };

    const checkFileExtensionDoc = (item) => {
        var fileExtension = item.file.split(".").pop();
        return fileExtension === "doc";
    }

    return (
        <div className={props.menu ? "screenLeftMargin w-100" : "screenLeftMarginResponse w-100"} style={{ marginTop: props.onBoardingStatus === false ? "-45px" : "" }}>
            <div className="row ml-3 mr-3 mobileResponsive">
                <div className="col-12">
                    <div className="heading-title">About Store</div>
                </div>
            </div>
            <div className="row ml-3 mr-3 mobileResponsive">
                <div className="col-12">
                    <ValidatorForm>
                        <div className="row">
                            <div className="col-lg-6 col-md-12">
                                <div className="d-flex">
                                    <div className="">
                                        <div className="row">
                                            <div className="col-12 mt-2">
                                                <div className="storeImageBlock">
                                                    <div className="imageUpload" {...getStoreLogo()}>
                                                        <input {...getStoreInputLogo()} />
                                                        {getStoreLogoActive ? (
                                                            <p>Drop the files here ...</p>
                                                        ) : (
                                                            <>
                                                                {state.store_logo.length > 0 ? (
                                                                    <>
                                                                        {state.store_logo.map((item) => {
                                                                            return (
                                                                                <div>
                                                                                    <img
                                                                                        className="imgTagBlock"
                                                                                        src={item?.original !== undefined ? item?.original : item?.file}
                                                                                        alt=""
                                                                                    />
                                                                                </div>
                                                                            );
                                                                        })}
                                                                    </>
                                                                ) : (
                                                                    <div className="uploadPhoto">
                                                                        <div style={{ cursor: "pointer" }} className="uploadInfo">
                                                                            <div className="uploadImage"><img width="22" src={UploadPhotoIcon} /></div>
                                                                            <div className="uploadText">Store &nbsp;Logo</div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ml-3">
                                        <div className="row">
                                            <div className="col-12 mt-2">
                                                <div className="storeImageBlock">
                                                    <div className="imageUpload" {...getStoreIcon()}>
                                                        <input {...getStoreIconInput()} />
                                                        {getStoreIconActive ? (
                                                            <p>Drop the files here ...</p>
                                                        ) : (
                                                            <>
                                                                {state.store_icon.length > 0 ? (
                                                                    <>
                                                                        {state.store_icon.map((item) => {
                                                                            return (
                                                                                <div>
                                                                                    <img
                                                                                        className="imgTagBlock"
                                                                                        src={item?.original !== undefined ? item?.original : item?.file}
                                                                                        alt=""
                                                                                    />
                                                                                </div>
                                                                            );
                                                                        })}
                                                                    </>
                                                                ) : (
                                                                    <div className="uploadPhoto">
                                                                        <div style={{ cursor: "pointer" }} className="uploadInfo">
                                                                            <div className="uploadImage"><img width="22" src={UploadPhotoIcon} /></div>
                                                                            <div className="uploadText">Store &nbsp;Front</div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    {/* <div className="col-3">
                                        <div className="row">
                                            <div className="col-12 mt-2">
                                                <div className="storeImageBlock">
                                                    <div className="imageUpload" {...getStoreLogo()}>
                                                        <input {...getStoreInputLogo()} />
                                                        {getStoreLogoActive ? (
                                                            <p>Drop the files here ...</p>
                                                        ) : (
                                                            <>
                                                                {state.store_logo.length > 0 ? (
                                                                    <>
                                                                        {state.store_logo.map((item) => {
                                                                            return (
                                                                                <div>
                                                                                    <img
                                                                                        className="imgTagBlock"
                                                                                        src={item?.original !== undefined ? item?.original : item?.file}
                                                                                        alt=""
                                                                                    />
                                                                                </div>
                                                                            );
                                                                        })}
                                                                    </>
                                                                ) : (
                                                                    <div className="uploadPhoto">
                                                                        <div style={{ cursor: "pointer" }} className="uploadInfo">
                                                                            <div className="uploadImage"><img width="22" src={UploadPhotoIcon} /></div>
                                                                            <div className="uploadText">Store Icon</div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-9">
                                        <div className="row">
                                            <div className="col-12 mt-2">
                                                <div className="storeImageBlock">
                                                    <div className="imageUpload" {...getStoreIcon()}>
                                                        <input {...getStoreIconInput()} />
                                                        {getStoreIconActive ? (
                                                            <p>Drop the files here ...</p>
                                                        ) : (
                                                            <>
                                                                {state.store_icon.length > 0 ? (
                                                                    <>
                                                                        {state.store_icon.map((item) => {
                                                                            return (
                                                                                <div>
                                                                                    <img
                                                                                        className="imgTagBlock"
                                                                                        src={item?.original !== undefined ? item?.original : item?.file}
                                                                                        alt=""
                                                                                    />
                                                                                </div>
                                                                            );
                                                                        })}
                                                                    </>
                                                                ) : (
                                                                    <div className="uploadPhoto">
                                                                        <div style={{ cursor: "pointer" }} className="uploadInfo">
                                                                            <div className="uploadImage"><img width="22" src={UploadPhotoIcon} /></div>
                                                                            <div className="uploadText">Store Front</div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}

                                    <div className="col-12 mt-3">
                                        <h5 className="h5-label">Tell us more about you and your store</h5>
                                    </div>

                                    <div className="col-12 mt-2">
                                        <div className="row">
                                            <div className="col-12 mt-2 d-flex">
                                                <div className="h6-lable-about-store d-flex">What describes you the best as a store?</div>
                                                {/*<div className="d-flex ml-3">
		                                            <div style={{cursor: "pointer"}} onClick={() => viewAllStoreType()} className="viewAll">
		                                                View all
		                                            </div>
		                                        </div>*/}
                                            </div>
                                        </div>

                                        <div className="row mt-3">
                                            <div className="col-12">
                                                <StoreSeviceTypeList
                                                    sendDataToParent={(item) => {
                                                        sendDataToParent(item);
                                                    }}
                                                    activeStore={openStoreCategories}
                                                    type="store"
                                                    isFullDataShow="no"
                                                />
                                            </div>
                                        </div>

                                        {/*<Modal isOpen={storeIsOpen} style={allStyles} overlayClassName="myoverlay">
		                                    <div style={{cursor: "pointer"}} onClick={() => closeAllStoreType()} className="x mb-2">
		                                        <CloseIcon />
		                                    </div>

		                                    <StoreSeviceTypeList
		                                        sendDataToParent={(item) => {
		                                            sendDataToParent(item);
		                                        }}
		                                        activeStore={openStoreCategories}
		                                        type="store"
                                                isFullDataShow="yes"
		                                    />
		                                </Modal>*/}
                                    </div>

                                    <div className="col-12 mt-3">
                                        <div className="row">
                                            <div className="col-12 mt-2 d-flex">
                                                <div className="h6-lable-about-store d-flex">How would you categorize your store?</div>
                                                <div className="d-flex ml-3">
                                                    <div style={{ cursor: "pointer" }} onClick={() => viewAllCategoryType()} className="viewAll">View all</div>
                                                </div>
                                            </div>
                                        </div>

                                        <Modal isOpen={categoryIsOpen} style={allStyles} overlayClassName="myoverlay">
                                            <div>
                                                <div style={{ cursor: "pointer" }} onClick={() => closeAllCategoryType()} className="x mb-2">
                                                    <CloseIcon />
                                                </div>
                                            </div>

                                            <StoreSeviceTypeList
                                                sendDataToCategory={(item) => {
                                                    sendDataToCategory(item);
                                                }}
                                                activeStore={openCategory}
                                                type="category"
                                                isFullDataShow="yes"
                                            />

                                            <div>
                                                <div style={{ cursor: "pointer" }} onClick={() => closeAllCategoryType()} className="x mb-2">
                                                    <Button name="OK" className="btn btn-primary" />
                                                </div>
                                            </div>

                                        </Modal>

                                        <div className="row mt-1">
                                            <div className="col-12">
                                                <StoreSeviceTypeList
                                                    sendDataToCategory={(item) => {
                                                        sendDataToCategory(item);
                                                    }}
                                                    activeStore={openCategory}
                                                    type="category"
                                                    isFullDataShow="no"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 mt-4">
                                        <div className="h6-lable-about-store">What is your current annual revenue?</div>
                                    </div>

                                    <div className="col-12 mt-2">
                                        <FormControl className="drop-value" variant="outlined" style={{ height: "20px" }}>
                                            <InputLabel>Revenue</InputLabel>
                                            <Select
                                                MenuProps={{ autoFocus: false }}
                                                labelId="revenue-select"
                                                id="revenue-dropdown"
                                                value={state?.current_annual_revenue}
                                                defaultValue={state?.current_annual_revenue}
                                                label="Revenue"
                                                name="current_annual_revenue"
                                                className="drop-value"
                                                onChange={handleChange}


                                            >
                                                {revenueOptions.length > 0 && revenueOptions.map((item) => {
                                                    return (
                                                        <MenuItem value={item?.slug}> {item?.cost} </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>
                                    </div>

                                    {/*<div className="col-12 mt-3">
		                                <div className="disclose">
		                                    <Checkbox
		                                        defaultChecked
		                                        color="primary"
		                                        checked={state.revenue_disclose}
		                                        onChange={() => handleCheckbox()}
		                                        inputProps={{ "aria-label": "secondary checkbox" }}
		                                    />
		                                    <span className="muiDescripationInfoCheckbox">Don't want to disclose my revenue</span>
		                                </div>
		                            </div>*/}
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-12">
                                <div className="row">
                                    <div className="col-12">
                                        <h5 className="h5-label">Your business address</h5>
                                    </div>
                                </div>

                                <div className="row mt-2">
                                    <div className="col-6">
                                        <TextValidator
                                            required
                                            errorMessages={[
                                                "Please Enter Store Name"
                                            ]}
                                            validators={[
                                                "required",
                                            ]}
                                            className="storeName w-100"
                                            inputProps={{ maxLength: 20 }}
                                            value={state?.name}
                                            onChange={handleChange}
                                            name="name"
                                            label="Store Name"
                                            variant="outlined"
                                            disabled={true}
                                        />
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-6">
                                        <TextValidator
                                            required
                                            errorMessages={[
                                                "Please Enter First Name"
                                            ]}
                                            validators={[
                                                "required",
                                            ]}
                                            className="w-100"
                                            inputProps={{ maxLength: 20 }}
                                            value={state?.first_name}
                                            onChange={handleChange}
                                            name="first_name"
                                            label="First Name"
                                            variant="outlined"
                                        />
                                    </div>
                                    <div className="col-6">
                                        <TextValidator
                                            required
                                            errorMessages={[
                                                "Please Enter Last Name",
                                            ]}
                                            validators={[
                                                "required",
                                            ]}
                                            className="w-100"
                                            inputProps={{ maxLength: 20 }}
                                            value={state?.last_name}
                                            onChange={handleChange}
                                            name="last_name"
                                            label="Last Name"
                                            variant="outlined"
                                        />
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-8">
                                        <Geosuggest
                                            id="aboutstore"
                                            ref={geosuggestEl}
                                            required
                                            className="Aboutstore_textfield"
                                            placeholder="Address"
                                            name="address"
                                            fields={["address_components", "geometry"]}
                                            types={["address"]}
                                            country={["US", "CA"]}
                                            initialValue={state?.address}
                                            onSuggestSelect={onSuggestSelect}
                                            location={new google.maps.LatLng(lat, lng)}
                                            radius="100"
                                        />
                                    </div>
                                    <div className="col-4">
                                        <TextValidator
                                            className="w-100"
                                            type="number"
                                            value={state?.unit}
                                            onChange={handleChange}
                                            name="unit"
                                            label="Unit"
                                            variant="outlined"
                                        />
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-6">
                                        <FormControl variant="outlined">
                                            <InputLabel required id="country-select">Country</InputLabel>
                                            <Select
                                                labelId="country-select"
                                                id="country-dropdown"
                                                value={state.country}
                                                onChange={handleCountryChange}
                                                label="Country"
                                                name="country"
                                                errorMessages={["Please Select Country"]}
                                                validators={[
                                                    "required",
                                                ]}
                                            >
                                                {countryList.map((item) => {
                                                    return (
                                                        <MenuItem value={item?.iso_3166_1_a3}> {item?.printable_name} </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="col-6">
                                        <FormControl variant="outlined">
                                            <InputLabel required id="country-select" >{state.country === 'CAN' ? 'Province' : 'State'}</InputLabel>
                                            <Select
                                                MenuProps={{ autoFocus: false }}
                                                labelId="country-select"
                                                id="country-dropdown"
                                                value={state?.state}
                                                defaultValue={state?.state}
                                                label={state.country === 'CAN' ? 'Province' : 'State'}
                                                name="state"
                                                errorMessages={["Please Select State"]}
                                                validators={[
                                                    "required",
                                                ]}
                                                onChange={(e) => handleStateChange(e)}
                                                onClose={() => setStateList(stateDataList)}
                                            >
                                                <ListSubheader>
                                                    <TextField
                                                        size="small"
                                                        autoFocus
                                                        placeholder="Type to search..."
                                                        fullWidth
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <SearchIcon />
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                        onChange={stateFilter}
                                                        onKeyDown={(e) => {
                                                            if (e.key !== "Escape") {
                                                                e.stopPropagation();
                                                            }
                                                        }}
                                                    />
                                                </ListSubheader>
                                                {stateList.length > 0 && stateList.map((item) => {
                                                    return (
                                                        <MenuItem value={item?.id}> {item?.stateName} </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-6">
                                        <FormControl variant="outlined">
                                            <InputLabel required id="country-select">City</InputLabel>
                                            <Select
                                                MenuProps={{ autoFocus: false }}
                                                labelId="country-select"
                                                id="country-dropdown"
                                                value={state?.city}
                                                defaultValue={state?.city}
                                                label="City"
                                                name="city"
                                                errorMessages={["Please Select City"]}
                                                validators={[
                                                    "required",
                                                ]}
                                                onChange={(e) => handleCityChange(e)}
                                                onClose={() => setCityList(cityDataList)}
                                            >
                                                <ListSubheader>
                                                    <TextField
                                                        size="small"
                                                        autoFocus
                                                        placeholder="Type to search..."
                                                        fullWidth
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <SearchIcon />
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                        onChange={cityFilter}
                                                        onKeyDown={(e) => {
                                                            if (e.key !== "Escape") {
                                                                e.stopPropagation();
                                                            }
                                                        }}
                                                    />
                                                </ListSubheader>
                                                {cityList.length > 0 && cityList.map((item) => {
                                                    return (
                                                        <MenuItem value={item?.id}> {item?.cityName} </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="col-6">
                                        {state.country === "CAN" ?
                                            <InputMask
                                                mask={"a9a 9a9"}
                                                value={state?.postal_code}
                                                disabled={false}
                                                onChange={handleChange}
                                            >
                                                {() => (
                                                    <TextValidator
                                                        required
                                                        errorMessages={[
                                                            "Please Enter Valid Postal Code"
                                                        ]}
                                                        validators={[
                                                            "required",
                                                        ]}
                                                        className="w-100"
                                                        name="postal_code"
                                                        label={'Postal Code'}
                                                        variant="outlined"
                                                    />
                                                )}
                                            </InputMask>
                                            :
                                            <InputMask
                                                mask={"99999"}
                                                value={state?.postal_code}
                                                disabled={false}
                                                onChange={handleChange}
                                            >
                                                {() => (
                                                    <TextValidator
                                                        required
                                                        errorMessages={[
                                                            "Please Enter Valid ZIP Code"
                                                        ]}
                                                        validators={[
                                                            "required",
                                                        ]}
                                                        className="w-100"
                                                        name="postal_code"
                                                        label={'ZIP Code'}
                                                        variant="outlined"
                                                    />
                                                )}
                                            </InputMask>
                                        }
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-6">
                                        <InputMask
                                            mask={"+1 (999) 999-9999"}
                                            maskChar="_"
                                            value={state?.contact_number}
                                            onChange={handleChange}
                                            disabled={false}
                                        >
                                            {() => (
                                                <TextValidator
                                                    required
                                                    errorMessages={[
                                                        "Please Enter Valid Phone Number"
                                                    ]}
                                                    validators={[
                                                        "required",
                                                    ]}
                                                    className="w-100"
                                                    name="contact_number"
                                                    label="Phone Number"
                                                    variant="outlined"
                                                />
                                            )}
                                        </InputMask>
                                    </div>
                                    <div className="col-6">
                                        <TextValidator
                                            className="w-100"
                                            inputProps={{ maxLength: 30 }}
                                            value={state?.website}
                                            onChange={handleChange}
                                            name="website"
                                            label="Website"
                                            variant="outlined"
                                        />
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-12">
                                        <div className="d-flex">
                                            <div>
                                                <h5 className="h5-label mt-3">Add business credentials</h5>
                                            </div>
                                            <Tooltip title="You can initiate the store setup process, but full activation will only occur following the thorough verification of your credentials.">
                                                <div className="mt-2 ml-3">
                                                    <a href="#">
                                                        <InfoIcon style={{ width: "25px", height: "35px", color: "grey" }} />
                                                    </a>
                                                </div>
                                            </Tooltip>
                                        </div>
                                        <div className="muiDescripationInfoAbout mt-1">
                                            Your business registration document (such as a incorporation certificate) and a
                                            government issued ID is required to verify and protect identity of your business.
                                            {/* You may launch your store now but it will only be activated after proper verification
                                            of your business credentials. */}
                                        </div>
                                    </div>
                                    <div className="col-12 mt-3 d-flex">
                                        {docsImagesList.length > 0 && docsImagesList.map((item, index) => {
                                            return (
                                                <div className="storeImageBlock d-flex mr-3"
                                                    data-key={index}
                                                    onDragStart={(e) => dragUploadStart(e, index)}
                                                    onDragEnter={(e) => dragUploadEnter(e, index)}
                                                    onDragEnd={dropUploadEnd}
                                                    key={index}
                                                    draggable>
                                                    <div className="ml-2 mr-3">
                                                        <div className="deleteUploadedImage text-center" onClick={() => handleDeleteImage(index)}>
                                                            <CloseIcon style={{ fontSize: "18px" }} />
                                                        </div>

                                                        {checkFileExtension(item) ? (
                                                            <a href={item.file} target="_blank">
                                                                <img className="imgTagBlock" src={PDF} alt="" />
                                                            </a>
                                                        ) :
                                                            checkFileExtensionDoc(item) ?
                                                                <a href={item.file} target="_blank">
                                                                    <img className="imgTagBlock" src={DOC} alt="" />
                                                                </a>
                                                                :
                                                                (
                                                                    <img className="imgTagBlock" src={item?.file} alt="" />
                                                                )}
                                                    </div>
                                                </div>
                                            );
                                        })}

                                        <div className="storeImageBlock d-flex">
                                            <div className="imageUpload" {...getStoreDocument()}>
                                                <input {...getStoreInputDocument()} />
                                                {getStoreDocumentActive ? (
                                                    <p>Drop the files here ...</p>
                                                ) : (
                                                    <div className="uploadPhoto">
                                                        <div style={{ cursor: "pointer" }} className="uploadInfo">
                                                            <div className="uploadImage"><img width="22" src={UploadPhotoIcon} /></div>
                                                            <div className="uploadText">Documents</div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-3 mobileResponsive">
                                    <div className="col-12 d-flex justify-content-end">
                                        <Button type="onSubmit" onClick={onSubmitHandle} name="Next" className="btn btn-primary mb-5" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ValidatorForm>
                </div>
            </div>
        </div>
    );

} export default AboutStore;