import React from "react";
import "./welcomeScreen.scss";
import Welcome from "./ThankyouPage.png";
// import ShopezLogo from "../../../assets/images/shopez_logo.png";
import WelcomeLogo from "../../../assets/images/shopez logo_wel.png";
import Button from "../../../components/button/button";
import thankYouBanner from "../../../assets/images/thank-you/thank-you-back.png";
import thanksLogo from "../../../assets/images/thank-you/thanks_welcome_logo.png";
import ShopezLogo from "../../../assets/images/thank-you/Logo.svg";
import ShopezBack from "../../../assets/images/arrow_left_alt.svg";
import { useHistory } from "react-router-dom";
const WelcomeScreen = () => {
    const history = useHistory();
    const gotoMainScreen = () => {
        history.push("/");
    };

    const gotoBack = async () => {
        history.push("/");
    };

    return (
        <div className="ThanksPasswordDeal container-fluid p-0 m-0"
            style={{
                background: `url(${thankYouBanner})`,
                backgroundRepeat: 'no-repeat',
                // backgroundSize: 'cover',
            }} >
            <div className="outerDiv row p-0 m-0">
                <div className="loginForm col-sm-12 pr-0 welcom_thanks" >
                    <div className="  ">
                        <div id="create-card" className=" ">{ /* pt-5  */}
                            <div className="container ">
                                <div class="verticall_thanks_center">
                                    <img className="logo_home img-fluid" src={ShopezLogo} />
                                    <div onClick={() => gotoBack()} className="back_set_welcome" style={{ cursor: "pointer" }}>
                                        <img className="back_home img-fluid" src={ShopezBack} />
                                    </div>

                                    <div className="container  ">
                                        <div className="text-center img_div "><img className="thanks_img_logo text-center img-fluid" src={thanksLogo} /></div>
                                        <h1 className="heading-title-t text-center pb-4">Welcome to Shopez!</h1>
                                        <div className="main-heading-login text-center">
                                            {/* Thank you for joining us on this exciting journey. */}
                                            Thank you tor expressiog interest in joining Shopezl!
                                        </div>
                                        <div className="main-heading-login text-center mt-2">
                                            {/* Together, we'll achieve greatness! Welcome onboard! */}
                                            Your preliminary details have been successfully recorded, and our team will reach out to you shortly
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="col-6">
                    <div className="loginForm">
                        <div id="create-card" className="create_welcome_card_cont m-5 p-5 w-100">
                            <div className="container">
                                <div className="welcomelogo">
                                    <img src={WelcomeLogo} />
                                </div>
                                <div className="welcome-text">
                                    <p>Thank you for your application !!</p>
                                    <p> You will hear back from us within 2 to 3 days.</p>
                                </div>
                                <div className="container text-center mt-5 pt-5">
                                    <Button onClick={() => gotoMainScreen()} className="btn btn-primary" name="Home"></Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WelcomeScreen;
