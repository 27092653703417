import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Button from "../../../../components/button/button";
import Modal from "react-modal";
import Pagination from '@mui/material/Pagination';
import { getMethod, deleteMethodWithToken } from "../../../../utils/services/api";
import urls from "../../../../utils/services/apiUrl.json";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import SpinnerLoader from "../../../../components/loader/spinnerLoader";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import LanguageIcon from '@material-ui/icons/Language';
import PhoneIcon from '@material-ui/icons/Phone';
import MailIcon from '@material-ui/icons/Mail';
import "./supplier.scss";
import OnHoldModal from "../../../../components/OnHoldModal/onHoldModal";

function Suppliers(props) {
    const history = useHistory();

    const itemsPerPage = 20;

    const [screenSize, getDimension] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    const customStyles = {
        content: {
            top: "40%",
            left: "50%",
            transform: screenSize.width <= 800 ? 'translate(-20%, -50%)' : 'translate(-42%, -50%)',
            backgroundColor: "white",
            border: "1px #ccc",
            borderRadius: "4px",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            outline: "none",
            padding: "15px",
            width: screenSize.width <= 800 ? "40vw" : screenSize.width <= 1100 ? "28vw" : "25vw",
            height: screenSize.width <= 800 ? "15vh" : "18vh",
        },
    };

    const [suppliersList, setSuppliersList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [confirmation, setConfirmation] = useState(false);
    const [indexIdToDelete, setIndexIdToDelete] = useState("");

    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [modalMe, setModalMe] = useState(false)

    useEffect(() => {
        getSuppliersList();
        getSteps()
    }, []);

    const getSteps = async () => {
        await getMethod(urls.getCustomerStripeId).then((response) => {
            if (response.status === 200) {
                setModalMe(response.data.on_hold_check_flag)
            }
        });
    };

    const getSuppliersList = async () => {
        setLoading(true);
        await getMethod(urls.suppliers).then((response) => {
            if (response.status === 200) {
                setCount(Math.ceil(response.data.count / itemsPerPage))
                setSuppliersList(response.data.results.response);
                setLoading(false);
            }
        }).catch((error) => {
            console.log("error", error);
        });
        setLoading(false);
    };

    const handlePageChange = async (event, value) => {
        setLoading(true);
        getSteps()
        setPage(value)

        await getMethod(urls.suppliers + "?page=" + value).then((response) => {
            if (response.status === 200) {
                setCount(Math.ceil(response.data.count / itemsPerPage))
                setSuppliersList(response.data.results.response);
                setLoading(false);
            }
        });

        setLoading(false);
    }

    const ToDelete = (id) => {
        setConfirmation(true);
        getSteps()
        setIndexIdToDelete(id);
    };

    const deleteSupplier = async (id) => {
        // setConfirmation(false);
        // setLoading(true);
        await deleteMethodWithToken(urls.suppliers + id).then((response) => {
            if (response.status === 200 || response.status === 201) {
                toast.success(response.data.response);
                getSteps()
                getSuppliersList()
                setConfirmation(false);
                setLoading(true);
            } else {
                toast.error(response.data.response)
            }
        });
        // setLoading(false);
    };

    return (
        <div className={props.menu ? "screenLeftMargin w-100" : "screenLeftMarginResponse w-100"}>
            <div className="row ml-3 mr-3 mt-4">
                <div className="col-6">
                    <h1 className="heading-title">Suppliers</h1>
                </div>
                <div className="col-6 d-flex justify-content-end">
                    <Button className="btn btn-cancle btn-lg" name="Add Supplier" onClick={() => history.push("/contacts/suppliers/create")}></Button>
                </div>
            </div>

            {count > 1 &&
                <div className="row ml-3 mr-3 mt-3 mobileResponsive">
                    <div className="col-12">
                        <div className="shopPagination">
                            <Pagination
                                count={count}
                                page={page}
                                showFirstButton
                                showLastButton
                                onChange={(event, value) => handlePageChange(event, value)}
                            />
                        </div>
                    </div>
                </div>
            }

            <div className="row ml-3 mt-3 mr-3">
                {!loading ?
                    <div className="col-12">
                        <div className="row">
                            <div className="col-1 f-600">S.No</div>
                            <div className="col-3 f-600">Name</div>
                            <div className="col-4 f-600">Address</div>
                            <div className="col-3 f-600">Info</div>
                            <div className="col-1 f-600">Actions</div>
                        </div>

                        {suppliersList && suppliersList.length > 0 ? suppliersList.map((item, index) => {
                            return (
                                <div key={index + 1} className="row ft-14 mt-3 pt-3 pb-3" style={{ background: "#F6F6F6" }}>
                                    <div className="col-1" style={{ cursor: "pointer", display: "flex", alignItems: "center" }} onClick={() => history.push("/contacts/suppliers/" + item.id)}>
                                        {index + 1}.
                                    </div>
                                    <div className="col-3" style={{ cursor: "pointer", display: "flex", alignItems: "center" }} onClick={() => history.push("/contacts/suppliers/" + item.id)}>
                                        {item?.first_name} {item?.last_name}
                                    </div>
                                    <div className="col-4" style={{ cursor: "pointer", display: "flex", alignItems: "center" }} onClick={() => history.push("/contacts/suppliers/" + item.id)}>
                                        {item?.address}, {item?.city}, {item?.state}, {item?.country}, {item?.postal_code}
                                    </div>
                                    <div className="col-3" style={{ cursor: "pointer", alignItems: "center" }} onClick={() => history.push("/contacts/suppliers/" + item.id)}>
                                        {item.email !== "" && item.email !== undefined && item.email !== null &&
                                            <div>
                                                <MailIcon fontSize="small" style={{ color: "#808080", marginRight: "5Px", marginTop: "-1px" }} />
                                                {item?.email}
                                            </div>
                                        }
                                        {item.phone_number !== "" && item.phone_number !== undefined && item.phone_number !== null &&
                                            <div>
                                                <PhoneIcon fontSize="small" style={{ color: "#808080", marginRight: "5Px", marginTop: "-1px" }} />
                                                {item?.phone_number}
                                            </div>
                                        }
                                        {item.website !== "" && item.website !== undefined && item.website !== null &&
                                            <div>
                                                <LanguageIcon fontSize="small" style={{ color: "#808080", marginRight: "5Px", marginTop: "-1px" }} />
                                                {item?.website}
                                            </div>
                                        }
                                    </div>
                                    <div className="col-1" style={{ cursor: "pointer", display: "flex", alignItems: "center" }}>
                                        <EditIcon fontSize="small" onClick={() => history.push("/contacts/suppliers/" + item.id)} style={{ cursor: "pointer", color: "#000000", marginRight: "5px" }} />
                                        <DeleteIcon fontSize="small" onClick={() => ToDelete(item.id)} style={{ cursor: "pointer", color: "#eb2813", marginLeft: "5px" }} />
                                    </div>
                                </div>
                            );
                        }) :
                            <div className="row ft-14 mt-3 pt-3 pb-3" style={{ background: "#F6F6F6", textAlign: "center", alignItems: "center", height: "75px" }}>
                                <div className="col-12 " >No suppliers found.</div>
                            </div>
                        }
                    </div>
                    :
                    <div className="col-12">
                        <SpinnerLoader />
                    </div>
                }
                <OnHoldModal isOnHold={modalMe} />
            </div>

            {count > 1 &&
                <div className="row ml-3 mr-3 mt-3 mb-5 mobileResponsive">
                    <div className="col-12">
                        <div className="shopPagination">
                            <Pagination
                                count={count}
                                page={page}
                                showFirstButton
                                showLastButton
                                onChange={(event, value) => handlePageChange(event, value)}
                            />
                        </div>
                    </div>
                </div>
            }

            <Modal isOpen={confirmation} style={customStyles} overlayClassName="myoverlay" closeTimeoutMS={500}>
                <div className="mt-4 mb-4">
                    <div className="row">
                        <OnHoldModal isOnHold={modalMe} />
                        <div className="col-12 text-center">
                            Are you sure you want to delete this supplier?
                        </div>
                        <div className="col-12 mt-4  d-flex justify-content-center">
                            <Button type="onSubmit" onClick={() => { setConfirmation(false); getSteps() }} name="Cancel" className="btn btn-cancle btn-sm mr-3" />
                            <Button type="onSubmit" onClick={() => { deleteSupplier(indexIdToDelete); }} name="Delete" className="btn btn-primary btn-sm" />
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
}
export default Suppliers;
